.nav-logo {
    display: flex;
    justify-content: flex-start;
    padding: 15px;
    align-items: center; }

.logo-img {
    max-width: 20vw; }

.prod-navigation {
    display: flex;
    margin-top: 10vh;
    padding: 15px;
    align-items: baseline; }

.selection-flex {
    display: flex;
    justify-content: flex-start;
    width: 50%; }

.selection-title {
    opacity: 0.2;
    margin-right: 1vw;
    margin-bottom: 0; }

.black-background .selection-title {
    opacity: 0.2; }

.selection-title:hover {
    opacity: 1; }

.black-background .selection-title.active {
    opacity: 1; }

.selection-title.active {
    opacity: 1 !important; }

.lang-selector.active {
    opacity: 1 !important; }

.filters span:hover {
    text-decoration: underline; }

.projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 15px;
    grid-gap: 5px;
    grid-auto-flow: dense; }

.projects-container img {
    width: 100%; }

.project-vid {
    display: none; }

.grid-item {
    position: relative;
    height: 30vh;
    object-fit: cover; }

.project-img, .project-vid {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%; }


.larger-placement {
    grid-column-end: span 2;
    grid-row-end: span 2;
    height: 61vh; }

.fashion, .commercial, .films, .music {
    opacity: 1; }

.project-title {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    padding: 5px 0 5px 5px;
    display: none;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-top: 1px solid white; }

.project-link {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%; }

.project-title-hover {
    position: absolute;
    top: 8px;
    left: 10px;
    display: none;
    color: white; }

@media screen and (max-width: 600px) {
    .logo-img {
        max-width: 55vw; }

    .prod-navigation {
        flex-direction: column; }

    .selection-flex {
        width: 100%; }

    .selection-title {
        margin-right: 3vw; }

    .projects-container {
        grid-template-columns: 1fr 1fr; }

    .grid-item {
        height: 15vh; }

    .larger-placement {
        height: 30vh; } }
