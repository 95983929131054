.photo-carousel {
    .carousel-cell {
        width: 100%;
        height: 80vh;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center; } }

.photo-carousel {
    margin-top: 5vh; }

.photo-carousel {
    .carousel-cell img {
        max-height: 70vh; } }


.carousel-nav .carousel-cell.is-nav-selected {
    filter: grayscale(0);
    opacity: 1; }

.carousel-nav {
    .carousel-cell {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: grayscale(1);
        opacity: 0.2; } }

.carousel-nav {
    .carousel-cell img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.flickity-button-icon {
    display: none; }

.photo-carousel {
    .flickity-prev-next-button {
        height: 100% !important;
        width: 40vw;
        background: transparent !important;
        border-radius: 0 !important; } }

.carousel-nav {
    .flickity-prev-next-button {
        background: transparent !important; } }
.photo-carousel {
    .flickity-prev-next-button.previous:hover {
        cursor: url('../img/arrow-left-line.svg'), auto; } }

.photo-carousel {
    .flickity-prev-next-button.next:hover {
        cursor: url('../img/arrow-right-line.svg'), auto; } }

.photo-title {
    text-transform: uppercase; }

.credits {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 36vh;
    justify-content: space-between; }

.credits p {
    margin: 0;
    font-size: 0.8em;
    margin-bottom: 15px;
    margin-right: 30px; }

.project-container {
    margin: 20px;
    margin-top: 5% !important;
    justify-content: space-between; }

.project-description {
    width: 50vw !important; }

.project-description p {
    margin-top: 0;
    margin-right: 5vw;
    font-size: 0.9em; }

.exit-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    opacity: 1;
    padding: 3px; }

.exit-btn:before, .exit-btn:after {
    position: absolute;
    left: 12px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: black; }

.exit-btn:before {
    transform: rotate(45deg); }

.exit-btn:after {
    transform: rotate(-45deg); }

.title-alone {
    position: fixed;
    bottom: 0;
    padding-left: 15px;
    margin-bottom: 1vh; }


.player .vp-controls {
    bottom: 20vh !important; }

.stills-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
    grid-gap: 10px;
    grid-auto-flow: dense;
    margin: 10px auto;
    width: 75%; }

.three {
    grid-template-columns: 1fr 1fr 1fr; }

.stills-container img {
    width: 100%; }

.still-item {
    position: relative;
    object-fit: cover; }

.project-title-flex {
    display: flex;
    justify-content: space-between;
    width: 98%; }

.more-info {
    position: fixed;
    bottom: 0;
    right: 15px;
    margin-bottom: 1vh;
    opacity: 0;
    transition: 0.5s; }

.opacity {
    opacity: 1; }

.video-embed {
    width: 75%;
    align-items: center;
    margin: 5vh auto 9vh auto; }

.flickity-page-dots {
        bottom: 0; }

@media screen and (min-width: 600px) {
    .inside-title {
        display: none; } }

@media screen and (max-width: 600px) {
    .photo-carousel {
        .carousel-cell img {
            max-width: 85%;
            max-height: 90%; } }

    .photo-carousel {
        .carousel-cell {
            height: 50vh;
            margin-top: 10vh; } }

    .flickity-prev-next-button {
        width: 20vw !important; }

    .carousel-img {
        max-width: 82%; }

    .content-flex {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 16% !important; }

    .credits {
        max-width: 100%;
        max-height: 100vh; }

    .about-intro {
        width: 100%; }

    .video-embed, .stills-container {
        width: 85%; }

    .mobile-container {
        display: flex;
        flex-direction: column;
        justify-content: center; }

    .video-embed {
        margin: 5vh auto 0 auto; }

    body {
        position: relative; }

    .carousel-nav {
        .flickity-viewport {
            left: 10px; } }

    .project-container {
        margin-top: 8% !important; }

    .flickity-page-dots {
        bottom: 0; }

    .project-description {
        width: 100% !important; }

    .opacity {
        opacity: 0; }

    .photo-title.about-btn-open {
        opacity: 0; }

    .inside-title {
        padding-left: 15px; } }
