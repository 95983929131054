.qty-input {
    max-width: 2em;
    border: none;
    outline: none;
    border-bottom: 1px solid black; }

.modal-img {
    width: 20em;
    height: 100%;
    object-fit: contain; }

li p {
    margin-bottom: 0.5rem; }


.booking-form input, .booking-form textarea {
    background-color: transparent;
    outline: none;
    border: black solid 1px;
    margin-top: 10px;
    color: black;
    padding: 0.5em 0.5em; }

.request {
    background-color: transparent;
    color: black;
    border: none;
    font-weight: bold;
    padding: 0;
    margin: 0; }

.order-notes {
    height: 20vh; }

.cart-item {
    width: 35vw; }

.cart-item input {
    border: none; }

.order-left-side {
    width: 35vw; }

.checkout-grid {
    display: grid;
    grid-template-columns: 1fr 0.1fr 0.1fr;
    border-bottom: 1px solid black;
    width: 35vw;
    text-align: center; }

.nice-number {
    display: inline-flex;
    justify-content: stretch; }


.nice-number input {
    vertical-align: middle;
    -moz-appearance: textfield;
    box-sizing: content-box;
    margin: 0;
    text-align: center; }


.nice-number input::-webkit-inner-spin-button,
.nice-number input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }



@media (min-width: 992px) {
    .modal-lg {
        max-width: 900px; } }

@media (max-width: 600px) {
    .checkout-grid, .order-left-side, .cart-item {
        width: 100%; }

    .request {
        margin-bottom: 10vh; }

    .start-date, .end-date {
        width: 100%; }

    .checkout-grid {
        font-size: 0.85em; }

    .checkout-grid .small-label {
        font-size: 0.85em; } }
