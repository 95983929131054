body {
    font-family: sofia-pro, sans-serif;
    margin: 0;
    letter-spacing: 1px; }

body, html {
    overflow-x: hidden;
    overflow-y: visible; }

h1, .h1, h2, .h2 {
    font-weight: 600;
    letter-spacing: 1px; }

h1, .h1 {
    font-size: 2em; }

h2, .h2 {
    font-size: 1.5em; }

.black-background {
    background-color: black;
    color: white;
    width: 100vw; }

.white-background, .white-background:after, .white-background:before {
    background-color: white !important;
    color: black; }

.border-white {
    border-color: white !important; }

a {
    color: inherit;
    text-decoration: none; }

a:hover {
    color: inherit; }

.mailto:hover {
    text-decoration: underline; }

.bold {
    font-weight: bold; }


.language-switcher {
    font-weight: bold;
    position: fixed;
    bottom: 0;
    right: 15px;
    z-index: 4;
    font-size: 1.3em;
    margin-bottom: 1vh; }

.lang-selector {
    opacity: 0.3; }

.lang-selector:hover {
    opacity: 1; }

.eng {
    display: none; }

.underline {
    text-decoration: underline; }
