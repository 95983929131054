.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 35vw;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0); }

.modal.right .modal-content {
    height: 100%; }

.modal.right .modal-body {
    padding: 15px 15px 80px; }

.modal.right.fade .modal-dialog {
    right: -35vw;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out; }

.modal.right.fade.show .modal-dialog {
    right: 0; }

.modal.right.fade.show .modal-content {
    overflow-y: auto; }

.cart-img {
    max-width: 5em;
    height: 100%; }

@media screen and (max-width: 768px) {
    .modal.right.fade .modal-dialog {
        right: -100vw; }

    .modal.right .modal-dialog {
        width: 100vw; }

    .row {
        margin-left: 0; } }
