.page-container {
    position: relative; }

.bold {
    font-weight: bold; }

.dropdown-content {
    display: none; }

.show {
    display: block; }

.submission {
    position: fixed;
    background-color: black;
    color: white;
    height: 100vh;
    top: 0;
    right: 0;
    display: none; }

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: black;
    background-color: transparent;
    opacity: 1; }

.nav-pills .nav-link {
    color: black;
    border-radius: 0;
    font-weight: bold;
    opacity: 0.3; }

.nav-pills .nav-link:hover {
    opacity: 1; }

.subnav {
    padding: 0.5rem 1rem;
    font-weight: bold; }

.dropdown-menu {
    background-color: rgba(255,255,255,0.8);
    border-radius: 0;
    border: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); }

.dropdown-item.nav-link.active {
    opacity: 1; }


.product-img {
    width: 18em;
    height: 12em;
    object-fit: contain; }



.product-container {
    max-width: 19em; }

.product-grid::after {
    content: "";
    flex: auto; }

.col {
    flex: 0 0 0%; }


.small-label {
    font-size: 0.7em;
    text-transform: uppercase; }

.small-text {
    font-size: 0.75em; }

.addToCart {
    border-bottom: 1px solid black; }

.addToCart:hover {
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid black; }

.inner-cart-btn {
    border: none;
    font-weight: bold; }

.dropdown-toggle:focus {
    outline: none; }

.dropdown-menu.show {
    display: grid;
    grid-template-columns: auto auto auto auto auto; }

.full-width.dropdown {
    position: static; }

.full-width.dropdown > .dropdown-menu {
    left: 0;
    right: 0;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px; }

.full-width.dropdown > .dropdown-menu > li > a {
    white-space: normal; }

.dropdown-item {
    margin: 2px 2px;
    position: relative;
    cursor: pointer; }

.dropdown-item.active, .dropdown-item:active {
    font-weight: bold; }

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
    background-color: transparent;
    color: black; }

.dropdown-item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 0;
    height: 1px;
    background-color: black;
    transition: width .3s ease-in-out; }

.dropdown-item:hover:after {
    width: 100%; }

.dropdown-item.active:after {
    width: 100%; }

.modal-content {
    border-radius: 0; }

.cart-btn {
    float: right;
    background-color: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    padding: .5rem 1rem;
    position: relative; }

.cart-btn:hover {
    text-decoration: underline; }

.delete-item:hover {
    color: red; }

button {
    background-color: transparent;
    outline: none;
    border: none;
    color: inherit; }

.product-img, .inner-cart-btn {
    cursor: pointer; }

.dropdown-toggle::after {
    transition: transform 0.15s linear; }

.show.dropdown .dropdown-toggle::after {
    transform: translateY(3px); }


#studioCarousel {
    width: 40vw; }

.modalCarousel {
    width: 25vw; }

.modalCarousel .carousel-control-next,
.modalCarousel .carousel-control-prev {
    filter: invert(100%); }

.studio-info-side {
    width: 48%; }

.recommended-img {
    width: 15em;
    height: 10em; }

.pre-click {
    transition: 0.2s; }

.post-click {
    display: none;
    transition: 0.2s; }

.cart-grid {
    display: grid;
    grid-template-columns: 25% 20% 22% 18%; }

.back-to:hover {
    text-decoration: underline; }

.days-total-price {
    justify-self: end; }

.fit-content {
    width: fit-content; }

#search-box {
    border: none;
    outline: none; }

@media screen and (min-width: 2000px) {
    .modalCarousel {
        width: 20em; } }

@media screen and (min-width: 768px) {
    .full-width.dropdown > .dropdown-menu {
        transform: translate3d(15px, 42px, 0px); }

    .page-container {
        margin-top: 10vh; }

    .modal-info-side {
        width: 30vw; }

    .mobile {
        display: none; } }
@media screen and (max-width: 768px) {
    .mobile {
        display: block; }

    .search-form {
        float: left;
        padding: 0.5rem 1rem 0.5rem 0; }

    .cart-grid {
        grid-template-columns: 27% 25% 30% 14%; }

    .dropdown-menu.show {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 750px;
        width: 95%; }

    .dropdown-menu li {
        margin-bottom: 2vh; }

    .full-width.dropdown > .dropdown-menu {
        transform: translate3d(15px,88px, 0px) !important; }

    .product-img {
        width: 10em;
        height: 8em;
        object-fit: contain; }

    .nav {
        width: 100%;
        justify-content: space-between; }

    .nav-link {
        padding: 0.5rem 0; }

    .page-container {
        min-height: 100vh;
        padding: 0 15px; }

    .modal-img {
        width: 12em;
        margin: 0 auto; }

    .studio-info-side {
        width: 100%; }

    #studioCarousel {
        width: 100%;
        margin-bottom: 1em; }

    .cart-btn {
        padding: 0.5rem 0 0.5rem 1rem; }

    .modalCarousel {
        width: 100% !important;
        padding-top: 1vh; } }
