
.layout {
    width: 50%;
    height: 100%; }

.images-right {
    position: -webkit-sticky;
    position: sticky;
    top: 50px; }

.grid-img-item {
    width: 55%;
    height: 83vh;
    object-fit: cover;
    position: absolute;
    right: 15px;
    top: 5px; }


.flex-item-50 {
    width: 50%;
    margin-right: 1.5em; }

.layout-flex {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px grey; }

.layout-flex p {
    margin: 0.5em 0;
    font-size: 0.85em; }

ul {
    padding: 0; }

li {
    list-style-type: none; }

.text-wrapper {
    position: relative;
    max-width: 40%;
    padding: 0 20px;
    margin-bottom: 20vh;
    opacity: 0.2; }

.extra-margin {
    margin-bottom: 30vh; }

.container-flex {
    display: flex;
    justify-content: space-between; }

.subtitle {
    margin: 1em 0; }

.grid-img-item {
    opacity: 0;
    transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease; }

.is--active {
    opacity: 1; }


.grid-list {
    position: relative; }

.test-box {
    height: 100vh;
    width: 100vw; }

.book-now {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    height: 400px;
    width: 100vw; }

.object-fit-cover {
    object-fit: cover !important; }

.booking-link {
    text-decoration: underline;
    text-underline-offset: 2px; }

.booking-link:hover {
    opacity: 0.5; }

.category-title {
    border-bottom: 1px solid grey;
    padding-bottom: 7px; }

.sticky-container {
    margin-top: 10vh; }

.intro-rental {
    text-transform: uppercase; }

.category-intro {
    opacity: 1; }

.space-bottom {
    margin-bottom: 2em; }

.rental-mobile-img {
    display: none; }

@media screen and (max-width: 768px) {
    .rental-mobile-img {
        display: block;
        width: 100%;
        margin-top: 1em; }

    .images-right {
        display: none; }

    .text-wrapper {
        max-width: 100%; }

    .container-flex {
        flex-direction: column-reverse; }

    .flex-item-50 {
        width: 100%; }

    .layout {
        width: 100%;
        padding-bottom: 2em; } }
