.navmenu-container {
    height: 100vh;
    background-color: black;
    width: 100%;
    color: white;
    display: none;
    z-index: 7;
    position: fixed; }


.navmenu-open {
    display: block; }


.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    height: 2px !important; }

.hamburger-inner.dark-mode {
    background-color: white !important; }

.hamburger-inner.dark-mode:after {
    background-color: white !important; }

.hamburger-inner.dark-mode:before {
    background-color: white !important; }


.burger-container {
    float: right;
    z-index: 8;
    position: fixed;
    top: 0;
    right: 0; }


.navmenu {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center; }

.prod-title:hover, .rental-title:hover {
    text-decoration: underline; }

.line {
    height: 141vh;
    width: 0;
    border-left: 2px solid white;
    transform: rotate(-45deg);
    animation-duration: 0.5s;
    animation-name: grow; }

.line-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    pointer-events: none; }

@keyframes grow {
    from {
        height: 350px; }
    to {
        height: 145vh; } }

.about-container {
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 0vh;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-top: 2px solid white;
    transition: 0.5s;
    opacity: 0; }

.about-container-white {
    background-color: rgba(255,255,255,0.6);
    border-top: 2px solid black; }

.content-flex {
    display: flex;
    padding: 15px;
    height: 100%;
    align-items: flex-start;
    margin-top: 7%;
    justify-content: space-between;
    width: 90%; }

.about-intro {
    width: 35vw;
    margin-bottom: 5vh; }

.about-btn {
    position: fixed;
    bottom: 0;
    z-index: 6;
    padding-left: 15px;
    transition: 0.5s;
    margin-bottom: 1vh;
    cursor: pointer; }

.arrow-white {
    color: white; }

.about-open {
    height: 66vh;
    opacity: 1; }

.about-btn-open {
    bottom: 58vh; }

.arrow-down {
    display: none; }

.team-link:hover {
    text-underline-offset: 2px; }


.contact-form input, .contact-form textarea {
    background-color: transparent;
    border: none;
    border-bottom: white solid 1px !important;
    outline: none;
    margin-top: 2px;
    color: white;
    font-family: inherit;
    padding-bottom: 10px; }

.form-white input, .form-white textarea {
    border-bottom: black solid 1px !important;
    color: black !important; }

#name, #email {
    width: 48%; }

.submit-btn {
    width: 100%;
    padding-bottom: 10px; }

.contact-form {
    width: 30%; }

.social-icons i {
    font-size: 1.5em;
    margin-right: 0.2em; }

@media screen and (max-width: 600px) {
    .about-container {
        overflow-y: scroll; }

    .about-open {
        height: 87%; }

    .about-btn-open {
        bottom: 79%; }

    .contact-form {
        width: 100%;
        padding-bottom: 10vh;
        padding-top: 5vh; }

    .desktop {
        display: none; }

    .about-intro {
        margin-bottom: 2vh !important; }

    #name, #email {
        width: 100%; }

    .contact-form input, .contact-form textarea {
        margin-bottom: 10px; } }
