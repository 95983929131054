.photo-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 15px;
    grid-gap: 5px;
    grid-auto-flow: dense; }

.locations-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 15px;
    grid-gap: 5px;
    grid-auto-flow: dense; }

.photo-img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.thumbnail-container {
    height: 100%;
    position: relative; }

.landscape {
    height: 50%; }

.thumbnail-container.portrait {
    grid-row-end: span 2; }


@media screen and (min-width: 600px) {
    .logo-mobile-img {
        display: none; } }

@media screen and (max-width: 600px) {
    .photo-grid {
        grid-template-columns: 1fr 1fr; }

    .logo-img {
        display: none; }

    .logo-mobile-img {
        display: block;
        width: 15vw; }

    .hamburger {
        padding: 20px 15px; } }
