@media screen and (min-width: 2000px) {
    .credits {
        max-width: 65vw; }

    .credits p {
        margin-bottom: 5vh; }

    .logo-img {
        max-width: 16vw; }

    .extra-margin {
        margin-bottom: 50vh; }

    .about-intro {
        width: 25vw; }

    .content-flex {
        width: 61%; }

    .about-btn-open {
        bottom: 50vh; }

    .about-open {
        height: 55vh; } }
