.showreel {
    width: 100vw;
    z-index: -2;
    position: relative;
    object-fit: cover; }

.dummy-header {
    width: 100vw;
    height: 100vh; }

.vimeo-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    overflow: hidden; }

.vimeo-wrapper iframe {
    width: 100vw;
    height: 56.25vw /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */;
    min-height: 100vh;
    min-width: 177.77vh /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.index-navmenu-container::before {
    width: 100%;
    height: 100px;
    position: absolute;
    top: -100px;
    left: 0;
    background: linear-gradient(to bottom, rgba(28,29,30,0) 0%,rgba(1,1,1,1) 100%);
    content: ''; }

.index-arrow {
    position: absolute;
    bottom: 2vh;
    left: 1vw;
    font-size: 1.5em;
    cursor: pointer;
    z-index: 2; }

.arrow-rotate {
    transform: rotate(180deg); }

.line-flex, .square-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 2; }

.logo-square {
    border: 2px solid white;
    height: 200px;
    width: 200px;
    position: fixed; }

.logo-line {
    border-left: 2px solid white;
    height: 350px;
    transform: rotate(-45deg);
    position: fixed;
    width: 0; }

.index-navmenu-container {
    height: 100vh;
    width: 100%;
    color: white;
    position: relative;
    z-index: 1;
    opacity: 0; }

.navmenu-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    opacity: 0;
    z-index: 1;
    transition: 0.4s; }

.opacity {
    opacity: 1; }

.prod-title, .rental-title {
    z-index: 1; }

.gsap-title {
    opacity: 0; }

.img-containers {
    position: absolute;
    top: 0;
    display: flex; }

.left-img-container {
    -webkit-backface-visibility: hidden /* the magic ingredient */;
    -webkit-transform: skew(45deg, 0);
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: -25%; }


.left-img {
    -webkit-transform: skew(-45deg, 0);
    position: relative;
    left: 50%; }


.right-img-container {
    -webkit-backface-visibility: hidden /* the magic ingredient */;
    -webkit-transform: skew(45deg, 0);
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    right: 25%; }


.right-img {
    -webkit-transform: skew(-45deg, 0);
    position: relative;
    left: -25%; }


.index-line-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    pointer-events: none; }

.index-navmenu {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center; }

@media screen and (max-width: 600px) {
    .logo-square {
        height: 150px;
        width: 150px; }

    .logo-line {
        height: 275px; }

    .index-navmenu, .navmenu {
        flex-direction: column; }

    .index-arrow {
        display: none; } }
